
import { Component, Vue } from 'vue-property-decorator'
import ItemHistoryComponent from '@/components/ItemHistory/Index.vue'
import { HistoryProgressInterface } from '@/store/types/History/History'
import { mapGetters } from 'vuex'
import { isThisSecond } from 'date-fns'
import OverlayBackground from '@/components/OverlayBackground/Index.vue'
import Unauthorized from '@/components/Unauthorized/index.vue';
import router from '@/router'
@Component({
  components: {
    ItemHistoryComponent,
    OverlayBackground,
    Unauthorized,
  },
  computed: {
    ...mapGetters('AuthModule', ['getCompanyIdSelected']),
    UnauthorizedRole() {
      const role = this.$store.state.AuthModule.AuthState.role;
      return !role.some((role: any) => role.includes("Cliente") || role.includes("History") || role.includes("Implementador"));
    },
  },
  head: {
    script: [{ type: 'application/javascript', src: '../js/analytics.js' }],
  },
})
export default class HistoryComponent extends Vue {
  private Business = this.$store.state.BusinessModule.BusinessState.businessClient
  public getCompanyIdSelected!: string

  private historyProgress: HistoryProgressInterface[] = []
  private loading = false
  private unauthorized = false
  // private verifyDate (template: any) {
  //   return false
  // }

  private mounted() {
    this.$mixpanel.track('Historial')
    this.getFrameworks()
    this.getHistory()
  }

  private getFrameworks() {
    this.loading = true
    this.$store
      .dispatch('PolimorficModule/getFrameworks')
      .then(() => {
        this.loading = false
      })
      .catch(() => {
        this.loading = false
      })
  }
  private getHistory() {
    this.loading = true
    const historyId = this.$store.state.AuthModule.AuthState.role.includes('Implementador')  ||  this.$store.state.AuthModule.AuthState.role.includes('Customer Success')
      ? this.getCompanyIdSelected
      : this.Business.id
    this.$store
      .dispatch('HistoryModule/getHistory', historyId)
      .then((data) => {
        data.data.snapshots.forEach((element: any, index: any) => {
          index === 0 ? (element.open = true) : (element.open = false)
        })

        this.historyProgress = data.data.snapshots
        this.unauthorized = false;
        this.loading = false
      })
      .catch((error) => {
        if (error.response.status === 403) {
          this.unauthorized = true;
        }
        if (error.response.status === 401) {
          router.push('/')
          location.reload()
        }
        this.loading = false
      })
  }
}
